:root {
    --primaryColor: #333333;
    --tranparentDarkBg: rgba(0, 0, 0, 0.6);
    --floatingBG: transparent;
    --backgroundColorSecondary: #F7F2ED;
    --secondaryColor: #EEEEEE;
    --secondaryBG: #000; 
    --backgroundColor: #EBEBEB;
    --selectBG: #F7F2ED;
    --border1: #CCCCCC;
    --textColor: #5C5C5C;
    --textPrimary: #000;
    --textSecondary: #fff;
    --inputBorderRadius: 1em;
    --buttonBorderRadius: 2em;
    --buttonTextPrimary: #fff;
    --buttonTextSecondary: #000;
    --sizeButtonRadius: 13em;
    --buttonBorderPrimary: #000;
    --buttonBorderSecondary: #000;
  }
  
  .theme-beinghuman {
    --primaryColor: #333333;
    --tranparentDarkBg: rgba(0, 0, 0, 0.6);
    --floatingBG: transparent;
    --backgroundColorSecondary: #F7F2ED;
    --secondaryColor: #EEEEEE;
    --secondaryBG: #000; 
    --backgroundColor: #EBEBEB;
    --selectBG: #F7F2ED;
    --border1: #CCCCCC;
    --textColor: #5C5C5C;
    --textPrimary: #000;
    --textSecondary: #fff;
    --inputBorderRadius: 1em;
    --buttonBorderRadius: 2em;
    --buttonTextPrimary: #fff;
    --buttonTextSecondary: #000;
    --sizeButtonRadius: 13em;
    --buttonBorderPrimary: #000;
    --buttonBorderSecondary: #000;
  }
  
  .theme-superkicks {
    --primaryColor: #333333;
    --tranparentDarkBg: rgba(0, 0, 0, 0.6);
    --floatingBG: transparent;
    --backgroundColorSecondary: #F7F2ED;
    --secondaryColor: #EEEEEE;
    --secondaryBG: #000; 
    --backgroundColor: #EBEBEB;
    --selectBG: #F7F2ED;
    --border1: #CCCCCC;
    --textColor: #5C5C5C;
    --textPrimary: #000;
    --textSecondary: #fff;
    --inputBorderRadius: 1em;
    --buttonBorderRadius: 2em;
    --buttonTextPrimary: #fff;
    --buttonTextSecondary: #000;
    --sizeButtonRadius: 13em;
    --buttonBorderPrimary: #000;
    --buttonBorderSecondary: #000;
  }
  .theme-enamor {
    --primaryColor: #DA188D;
    --tranparentDarkBg: rgba(0, 0, 0, 0.6);
    --floatingBG: #eed9de;
    --backgroundColorSecondary: #eed9de;
    --secondaryColor: #000000;
    --backgroundColor: #eed9de;
    --secondaryBG: #000; 
    --selectBG: #000;
    --border1: #000;
    --textColor: #888888;
    --textPrimary: #000;
    --textSecondary: #DA188D;
    --inputBorderRadius: 0;
    --buttonBorderRadius: 0;
    --buttonTextPrimary: #fff;
    --buttonTextSecondary: #DA188D;
    --sizeButtonRadius: 0;
    --buttonBorderPrimary: #DA188D;
    --buttonBorderSecondary: #000;
  }

  .theme-indianterrain {
    --primaryColor: #3e4d70;
    --tranparentDarkBg: rgba(0, 0, 0, 0.6);
    --floatingBG: transparent;
    --backgroundColorSecondary: #F7F2ED;
    --secondaryColor: #EEEEEE;
    --secondaryBG: #000; 
    --backgroundColor: #EBEBEB;
    --selectBG: #F7F2ED;
    --border1: #CCCCCC;
    --textColor: #5C5C5C;
    --textPrimary: #000;
    --textSecondary: #fff;
    --inputBorderRadius: 1em;
    --buttonBorderRadius: 2em;
    --buttonTextPrimary: #fff;
    --buttonTextSecondary: #000;
    --sizeButtonRadius: 13em;
    --buttonBorderPrimary: #000;
    --buttonBorderSecondary: #000;
  }

  .theme-rarerabbit {
    --primaryColor: #141414;
    --tranparentDarkBg: rgba(0, 0, 0, 0.6);
    --floatingBG: transparent;;
    --backgroundColorSecondary: #f8f7f4;
    --secondaryColor: #F8F7F4;
    --backgroundColor: #F8F7F4;
    --secondaryBG: #000; 
    --selectBG: #f8f7f4;
    --border1: #CCCCCC;
    --textColor: #5C5C5C;
    --textPrimary: #1C1B1B;
    --textSecondary: #fff;
    --inputBorderRadius: 0;
    --buttonBorderRadius: 0;
    --buttonTextPrimary: #fff;
    --buttonTextSecondary: #1C1B1B;
    --sizeButtonRadius: 0;
    --buttonBorderPrimary: #1C1B1B;
    --buttonBorderSecondary: #1C1B1B;
  }